<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div class="vx-row mb-2" v-if="dataEdit">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Debit Note Adjustment Code"
          name="Debit Note Adjustment Code"
          v-model="dataEdit.adjustment.Code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Debit Note</label>
        <multiselect
          class="selectExample"
          v-model="debitNote.selected"
          :options="debitNote.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="dn_code"
          label="dn_code"
          :disabled="disabled == true"
          :searchable="true" 
          :loading="isLoading" 
           @search-change="asyncFindDebitNote"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.dn_code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.dn_code }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="debitNote == null && status_submit == false"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Debit Note Type"
          name="Debit Note Type"
          v-model="dn_type"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Code"
          name="Supplier Code"
          v-model="supplier_code"
          readonly
        />
      </div>
    </div><div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Name"
          name="Supplier Name"
          v-model="supplier_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-textarea
          class="w-full"
          label="note"
          v-model="note"
          :disabled="disabled == true"
          :class="classReadonly"
        />
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%" v-if="!dataEdit">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xlx, .doc, .docx"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.path)"
                  />
                </vx-tooltip>
              </td>
              <!-- <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i,tr.id)"
                    />
                  </vx-tooltip>
                </template>
              </td> -->
            </tr>
          </template>
        </table>
      </div>
    <div
      class="vx-row mb-2"
      style="
        border-bottom: 1px solid #c1bbff;
        padding-top: 10px;
        max-width: 50%;
        margin: auto;
      "
    ></div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          label="DN Value"
          class="w-full input-readonly"
          v-model="dn_value"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          label="Tax Value"
          class="w-full input-readonly"
          v-model="tax_value"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          label="Total DN Value"
          class="w-full input-readonly"
          v-model="total_dn"
          readonly
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          label="Apply Value"
          class="w-full input-readonly"
          v-model="apply_value"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          label="Remaining Value"
          class="w-full input-readonly"
          v-model="remaining_value"
          readonly
        />
      </div>
      <div class="vx-col w-1/3">
      <vx-tooltip text="History">
        <vs-button
          type="line"
          icon-pack="feather"
          @click.stop="showHistory(debitNote.selected)"
          icon="icon-eye">History</vs-button>
      </vx-tooltip>
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%">Adjustment</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
      {{classReadonly}}
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Adjustment Value"
          name="Adjustment Value"
          v-model="adjustment_value"
          placeholder="Adjustment Value"
          @keypress="isNumber($event)"
          @input="formatNumber($event)"
          :readonly="disabled == true"
          :class="classReadonly"
        />
        <span
          class="text-danger text-sm"
          v-if="adjustment_value == null && status_submit"
          >This field is required</span
        >
      </div>

      <div class="vx-col w-1/2">
        <label class="vs-input--label">Posting Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Posting Date"
          v-model="posting_date"
          placeholder="Posting Date"
          :disabled="disabled == true"
          :class="classReadonly"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="posting_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-textarea
          class="w-full"
          label="reason"
          v-model="reason"
          :disabled="disabled == true"
          :class="classReadonly"
        />
        <span
          class="text-danger text-sm"
          v-if="reason == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">COA</label>
        <multiselect
          class="selectExample"
          v-model="coa"
          :options="optionCoa"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Code"
          label="Code"
          :disabled="disabled == true"
          :class="classReadonly"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="coa == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Control Value"
          name="Control Value"
          v-model="control_value"
          readonly
        />
      </div>
      <!-- <span class="text-danger text-sm" v-show="errors.has('ControlValue')">{{errors.first("ControlValue")}}</span> -->
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Cost Center</label>
        <multiselect
          class="selectExample"
          v-model="costCenter"
          :options="optionCostCenter"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Name"
          label="Name"
          :disabled="disabled == true"
          :class="classReadonly"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="costCenter == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    
    
    <div class="vx-row mb-6 mt-6" v-if="!dataEdit">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button class="mb-2" @click="handleSubmit"
          >Save</vs-button
        >
      </div>
    </div>

     <vs-popup fullscreen title="History" :active.sync="history">
			<div class="vx-row mb-6">
				<div class="vx-col w-full" v-if="history">
					<datatableHistory :selected="selected"></datatableHistory>
				</div>
			</div>
		</vs-popup>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import datatableHistory from "./datatable-history.vue";
export default {
  components: {
    Datepicker,
    datatableHistory
  },
  props: ['dataEdit'],
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
			  history: false,
        selected: null,
        status_submit: false,
        dn_type: null,
        supplier_code: null,
        supplier_name: null,
        //debitNote: null,
        dn_value: null,
        tax_value: null,
        total_dn: null,
        apply_value: null,
        remaining_value: null,
        note: null,
        adjustment_value: null,
        control_value: null,
        reason: null, 
        posting_date: null,
        costCenter: null,
        coa: null,
        debitNote: {
          selected: null,
          options: [],
        },
        classReadonly : '',
        disabled : false,
        //optionDebitNote: [],
        optionCostCenter: [],
        optionCoa: [],
        fileAttachment: [],
        paramsUpload:[],
        adjustment_id: 0,
      isLoading: false
      };
    },
    closeHistory() {
		    this.history = false;
		},
    showHistory(data) {
      this.selected = data.dn_id;
			this.history = true;
		},
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    paramData() {
      return {
        debit_note_id: this.debitNote.selected.dn_id,
        cost_center_id: this.costCenter.ID,
        chart_of_account_id: this.coa.ID,
        adjustment_value: parseFloat(this.adjustment_value.replace(/,/g, '')),
        control_value: parseFloat(this.control_value.replace(/,/g, '')),
        remaining_value: parseFloat(this.remaining_value.replace(/,/g, '')),
        posting_date: moment(this.posting_date).format('YYYY-MM-DD'),
        reason: this.reason,
        note: this.note,
        file: this.paramsUpload,
      };
    },
    handleSubmit() {

      if (this.debitNote != null) {
        this.status_submit = true;
        //result = false;
      }

      this.$validator.validateAll().then((result) => {
        // if (this.control_value < 0){
        //     this.errors.add({
        //       field: "ControlValue",
        //       msg: "Control Value must >= 0",
        //     });
        //   result = false;
        // }

        if (result) {
          this.$vs.loading();
          this.postData();          
        }
      });
    },
    postData() {
      this.$http
        .post("/api/v1/debit-note/adjustment", this.paramData(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.adjustment_id = resp.data.ID
            if (this.$refs.file.files.length > 0) {
              this.file = this.$refs.file.files;
              for(let i =0; i < this.$refs.file.files.length; i++) {
                this.uploadData(i)
              }
              document.querySelector("#fileInput").value = "";
            }
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    asyncFindDebitNote(query) {
      this.isLoading = true
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            order: "code",
            sort: "asc",
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: 1,
            search: query,
            length: 5,
            type: 'all'
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.debitNote.options = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get debit note option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.isLoading = false
        });
    },
    // getOptionDebitNote() {
    //   this.$vs.loading();
    //   this.$http
    //     .get("/api/v1/debit-note/dn", {
    //       params: {
    //         order: "code",
    //         sort: "asc",
    //         territory_id: this.$userLogin.territory_id,
    //         territory_area_id: this.$userLogin.territory_area_id,
    //         status: 1,
    //         type: 'manual'
    //       },
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.debitNote.options = resp.data.records;
    //       } else {
    //         this.$vs.notify({
    //           title: "Error",
    //           text: "Failed to get debit note option",
    //           color: "danger",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-check",
    //         });
    //       }
    //       this.$vs.loading.close();
    //     });
    // },
    getOptionCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCostCenter = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this
            resp.data.records.forEach(function(e){
              _this.optionCoa.push(e.coa);
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getData() {
      console.log("this.dataEdit", this.dataEdit)
      this.$vs.loading();
      this.classReadonly = 'input-readonly'
      this.disabled = true
      this.note = this.dataEdit.adjustment.Note
      this.reason = this.dataEdit.adjustment.Reason
      this.posting_date = this.dataEdit.adjustment.PostingDate
      this.adjustment_value = this.dataEdit.adjustment.AdjustmentValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.control_value = this.dataEdit.adjustment.ControlValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.remaining_value = this.dataEdit.adjustment.RemainingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.setDebitNoteSelected(this.dataEdit.debit_note.ID);
      this.setCoaSelected(this.dataEdit.adjustment.ChartOfAccountID);
      this.setCostCenterSelected(this.dataEdit.adjustment.CostCenterID);

      for(var k = 0; k < this.dataEdit.dn_adjustment_attachment.length; k++){
        this.fileAttachment.push({
          id: this.dataEdit.dn_adjustment_attachment[k].ID,
          name: this.dataEdit.dn_adjustment_attachment[k].NameFile,
          path: this.dataEdit.dn_adjustment_attachment[k].PathFile,
        });
      }
      this.$vs.loading.close();
    },
    setDebitNoteSelected(id) {
      this.$vs.loading();
      this.$http
      .get("/api/v1/debit-note/dn", {
          params: {
            id: id,
            status: 1,
            type: 'all'
          },
        })
      .then((resp) => {
        if (resp.status == "success") {
          this.debitNote.selected = resp.data.records[0];
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCoaSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.coa = resp.data[0].coa;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCostCenterSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
        if (resp.status == "success") {
          this.costCenter = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatNumber(string) {
        if(string) {
            this.adjustment_value = string.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.control_value = (this.remaining_value.replace(/,/g, '') - string.replace(/,/g, '')).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
            return 0;
        }
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.adjustment_id);
      form.append("file", this.file[array]);
      return form;
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/debit-note/adjustment/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/adjustment/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.dataEdit) {
      this.getData();
    }
    //this.getOptionDebitNote();
    this.getOptionCostCenter();
    this.getOptionCoa();
  },
  computed: {},
  watch: {
    dataEdit() {
      Object.assign(this.$data, this.initialState());
      this.getData();
    },
    "debitNote.selected": function (val) {
      console.log("debitNote.selected", val)
      if (val != null) {
        this.dn_type = val.type
        this.supplier_code = val.supplier_code
        this.supplier_name = val.supplier_name
        this.dn_value = val.dpp == 0 ? val.dn_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : val.dpp.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.tax_value = val.tax_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.total_dn = val.total_dn.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.apply_value = val.used_dn_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (!this.dataEdit) {
          this.remaining_value = val.remaining_dn_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
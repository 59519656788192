<template>
  <vx-card title="DN Adjustment">
    <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? mainShow : '',detailHide]">
        <vs-tabs>
          <vs-tab label="Open">
            <open :key="componentKey"></open>
          </vs-tab>
          <vs-tab label="Confirmed">
            <div class="tab-text">
              <confirmed></confirmed>
            </div>
          </vs-tab>
          <vs-tab label="Canceled">
            <div class="tab-text">
              <canceled></canceled>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <form-create @close="handleClose"> </form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Open from "./open";
import Confirmed from "./confirmed";
import Canceled from "./canceled";
import FormCreate from "./form.vue";
export default {
  components: {
    Open,
    Confirmed,
    Canceled,
    FormCreate,
  },
  data() {
    return {
      mainShow: "core vx-col md:w-1/3 w-full mb-base",
      detailShow: "core vx-col md:w-2/3 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      componentKey: 0,
    };
  },
  methods: {
    handleClose() {
      
      console.log("close create")
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>